import React from "react";
import Typography from "@mui/material/Typography";
import { AppBar, Container, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

const styles = {
  root: {
    flexGrow: 1,
  },
  appbar: {
    alignItems: "center",
  },
  typography: {
    flexGrow: 1,
    align: "center",
    textAlign: "center",
  },
};

const Footer = (props) => {
  const { classes } = props;
  return (
    <footer className={classes.root}>
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        className={classes.appbar}
      >
        <Container maxWidth="md">
          <Toolbar>
            <Typography
              variant="caption"
              color="inherit"
              className={classes.typography}
            >
              © {new Date().getFullYear()} Whitney Light. All Rights Reserved.
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </footer>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
