import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

const styles = {
  root: {
    flexGrow: 1,
    padding: "1.5em",
  },
  card: {
    minWidth: 340,
    maxWidth: 340,
  },
  media: {
    height: 278.41,
  },
};

const Albums = (props) => {
  const { classes } = props;
  const albums = props.itemData;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {albums.map((item, index) => (
          <Grid item key={index} xs={3}>
            <Card variant="outlined" style={styles.card}>
              <CardActionArea
                key={index}
                href={`#/album?id=${item.album}&repo=${item.repo}`}
              >
                <CardMedia
                  component="img"
                  image={item.src}
                  alt={item.album}
                  style={styles.media}
                  repo={item.repo}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    align="center"
                    component="div"
                  >
                    {item.album}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Albums.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Albums);
