import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import ImageMasonry from "../components/ImageMasonry";
import Footer from "../components/Footer";
import { useSearchParams } from "react-router-dom";

function AlbumPage() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const repo = searchParams.get("repo");
  const [images, setImages] = useState([]);
  const [readme, setReadme] = useState([]);

  useEffect(() => {
    let photos = [];

    const fetchData = async () => {
      try {
        const response = await axios(
          "https://api.github.com/repos/w-light/" + repo + "/contents/" + id
        );
        const data = response.data;
        for (let j = 0; j < data.length; j++) {
          if (
            data[j].name.includes("jpeg") ||
            data[j].name.includes("jpg") ||
            data[j].name.includes("png")
          ) {
            let photo = {
              src: data[j].download_url,
              album: id,
            };
            photos.push(photo);
          }

          if (data[j].name.includes("md")) {
            const rme = await axios(data[j].download_url);
            const rmeData = rme.data;
            setReadme(rmeData);
          }
        }
      } catch (error) {}

      setImages(photos);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <article
        dangerouslySetInnerHTML={{ __html: readme }}
        style={{ margin: "10" }}
      ></article>
      <ImageMasonry itemData={images} />
      <Footer />
    </div>
  );
}

export default AlbumPage;
