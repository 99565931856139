import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Albums from "../components/Albums";
import config from "../config.json";

function HomePage() {
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    let repos = config.ACTIVE_REPOS;
    let albums = [];
    let collection = [];

    function addToCollection(photo) {
      var found = false;
      for (let index = 0; index < collection.length; index++) {
        if (collection[index].album === photo.album) {
          found = true;
          break;
        }
      }
      if (!found) {
        collection.push(photo);
      }
    }

    const fetchData = async () => {
      for (let x = 0; x < repos.length; x++) {
        try {
          const response = await axios(repos[x]);
          const data = response.data;
          for (let i = 0; i < data.length; i++) {
            albums.push({
              name: data[i].name,
              url: data[i].url,
            });
          }

          for (let i = 0; i < albums.length; i++) {
            const response = await axios(albums[i].url);
            const data = response.data;
            for (let j = 0; j < data.length; j++) {
              if (
                data[j].name.includes("jpeg") ||
                data[j].name.includes("jpg") ||
                data[j].name.includes("png")
              ) {
                let photo = {
                  src: data[j].download_url,
                  album: albums[i].name,
                  repo: repos[x].split("/")[5],
                };
                addToCollection(photo);
              }
            }
          }
        } catch (error) {}
      }
      setCollection(collection);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <Albums itemData={collection} />
      <Footer />
    </div>
  );
}

export default HomePage;
