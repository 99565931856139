import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Masonry from "@mui/lab/Masonry";
import Carousel, { Modal, ModalGateway } from "react-images";
import usePagination from "../Pagination";
import Pagination from "@mui/material/Pagination";

export default function ImageMasonry(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const count = Math.ceil(props.itemData.length / PER_PAGE);
  const _DATA = usePagination(props.itemData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Box>
      <br />
      <Pagination
        count={count}
        size="large"
        page={page}
        color="secondary"
        onChange={handleChange}
      />
      <br />
      <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={1} sx={{ ml: 0.1 }}>
        {_DATA.currentData().map((item, index) => (
          <Stack key={index}>
            <img
              src={item.src}
              srcSet={item.src}
              alt={item.album}
              loading="lazy"
              style={{ borderRadius: 4 }}
              onClick={() => openLightbox(index)}
            />
          </Stack>
        ))}
      </Masonry>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={() => closeLightbox()}>
            <Carousel
              currentIndex={currentImage}
              views={_DATA.currentData().map((x) => ({
                ...x,
                srcset: x.src,
                caption: x.album,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Box>
  );
}
